body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:active {
  text-decoration: none;
  color: inherit;
}

.page {
  position: relative;
  display: flex;
  justify-content: center;
}

.image-container {
  margin-top: 3vh;
  position: relative;
  background-color: #edcab2;
  height: 800px;
}

.image-container-mobile {
  margin-top: 5vh;
}

.base-image {
  width: 600px;
  height: 808.53px;
}

.image-mobile {
  height: 70vh;
}

.pixi-base-layer {
  position: relative;
}

.pixi-distortion-layer {
  position: absolute;
  /* max-height: 800px; */
}

.text-container {
  width: 35vh;
  position: fixed;
  padding-top: 10vh;
  margin-left: -17rem;
  font-size: 6vh;
  /* pointer-events: none; */
  z-index: 100;
}

.text-container-mobile {
  width: 20vh;
  position: absolute;
  padding-top: 10vh;
  margin-left: -6rem;
  font-size: 3vh;
  /* pointer-events: none; */
  color: white;
}

.l1 {
  padding-top: 5vh;
}
.l2 {
  margin-top: 10vh;
  color: #b578ba;
  background-color: #a2c4da;
}
.l3 {
  color: #b578ba;
  background-color: #a2c4da;
}
.l4 {
  margin-top: 10vh;
  text-decoration: line-through;
}
.l5 {
}

/* ANIMATIONS */

.lr-shake {
  -webkit-animation: lr-shake 2s linear;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes lr-shake {
  0% {
    margin-left: 0vh;
  }
  50% {
    margin-left: 1vh;
  }
  100% {
    margin-left: 0vh;
  }
}

.rl-shake {
  -webkit-animation: rl-shake 2s linear;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes rl-shake {
  0% {
    margin-left: 0vh;
  }
  50% {
    margin-left: -1vh;
  }
  100% {
    margin-left: 0vh;
  }
}
